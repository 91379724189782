"use client";

import { ChakraProvider, ColorModeScript, extendTheme } from "@chakra-ui/react";
import { GetSessionResult } from "@yotta-vision/functions/rest/session";
import type * as React from "react";
import { workSans } from "~lib/fonts";
import { SessionProvider } from "~lib/future/contexts/session";
import theme_ from "~lib/theme";

const theme = extendTheme(theme_, {
  fonts: {
    heading: workSans.style.fontFamily,
    body: workSans.style.fontFamily,
    mono: workSans.style.fontFamily,
  },
});

interface Props extends React.PropsWithChildren {
  session: GetSessionResult;
}

export function Providers({ session, children }: Props) {
  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <SessionProvider session={session}>
        <ChakraProvider theme={theme}>{children}</ChakraProvider>
      </SessionProvider>
    </>
  );
}
